// tutorGrada-routes.js
angular.module('neo.home.tutorGrada')
  .config(['$stateProvider', function($stateProvider) {
    $stateProvider.state('home.tutorGrada', {
      url: '/tutorGrada',
      templateUrl: 'home/tutorGrada/tutorGrada.tpl.html',
              data: {
          townHall: undefined,
          initDate: undefined,
          endDate: undefined
        },
      controller: 'TutorGradaCtrl',
      controllerAs: 'vm',
        resolve: {
          townHalls: function (Ajuntaments) {
            return Ajuntaments.query().$promise;
          },
          currentSeason: function(CurrentSeason) {
            return CurrentSeason.get().$promise;
          }
        }
    });
  }]);
